<template>
  <div class="wrap-content" id="wrap-content">
    <Header />
    <div class="main">
      <div class="main-left">
        <AsideSearch ref="aside" />
      </div>
      <div class="main-right">
        <div class="term">
          <template v-for="(val, key, i) in leftObj" :key="key">
            <template v-if="val.data.length > 0">
              <template v-for="(item, index) in val.data" :key="index">
                <div class="term-item" v-if="item.isChecked" @click="handleRemove(item, key)">
                  <span>{{ item._id }}</span>
                  <div class="icon">
                    <img src="../../assets/img/移除-选中.png" alt="" />
                  </div>
                </div>
              </template>
            </template>
          </template>
        </div>
        <div class="count">
          <p>
            共 <span class="bold">{{ total }}</span> 项
          </p>
          <Dropdown @on-click="handleMenu" trigger="click" style="cursor: pointer">
            <p>默认排序 <Icon type="ios-arrow-down"></Icon>
            </p>
            <template #list>
              <DropdownMenu>
                <DropdownItem name="auditDataTime">复核通过时间</DropdownItem>
              </DropdownMenu>
            </template>
          </Dropdown>
        </div>
        <List v-show="total > 0" />
        <noData v-if="total == 0" />
      </div>
    </div>
    <Footer />
  </div>
</template>
  
  <script setup>
import { ref, inject, provide, onMounted, reactive , onBeforeUnmount } from "vue";

import Header from "./components/Header.vue";
import List from "./components/list.vue";  
import AsideSearch from "@/components/AsideSearch";
import Footer from "@/components/footer.vue";
import noData from "@/components/noData.vue";
import bus from "@/utils/bus.js";
import { set } from "immutable";
let aside = ref(null);

let total = ref(0);

//获取数据
const getListData = () => {
  aside.value.setData(term);
};

//删除选中条件
const handleRemove = (item, key) => {
  let data = leftObj.value[key].data;
  data.map((each) => {
    if (each._id == item._id) {
      each.isChecked = false;
    }
  });
  bus.emit("returnData", leftObj.value);
};

//选择排序条件
let sort = ref("auditDataTime");
const handleMenu = (name) => {
  //通知排序条件
  bus.emit("sort", name);
};
//数组去重
const removal = (tempArr) => {
  for (let i = 0; i < tempArr.length; i++) {
    for (let j = i + 1; j < tempArr.length; j++) {
      if (tempArr[i].value == tempArr[j].value) {
        tempArr.splice(j, 1);
        j--;
      }
    }
  }
  return tempArr;
};

let leftObj = ref({});
onMounted(() => {
  // bus.on("selectArr", (arr) => {
  //   selection.value = removal([...selection.value, ...arr]);
  // });
  bus.on("noticeTotal", (val) => {
    total.value = val;
  });
  handleMenu("auditDataTime");
  bus.on("sendData", (val) => {
    leftObj.value = val;
  });

  bus.on("wrap-content", () => {
    var divElement = document.getElementById("wrap");
    divElement.scroll({
      top: 0,
      behavior: "smooth" // 可以添加平滑滚动效果
    });
  });

});

onBeforeUnmount(() => {
  bus.all.delete("noticeTotal");
  bus.all.delete("sendData");
  bus.all.delete("wrap-content");
});
</script>
  
  <style lang="scss" scoped>
.wrap-content {
  padding: 0 40px;
  background: #fff;
  min-height: 100vh;
}
.main {
  display: flex;
  margin-top: 10px;
  min-height: calc(100vh - 120px);
  .main-left {
    width: 320px;
  }
  .main-right {
    width: calc(100% - 370px);
    margin-left: 50px;
    .count {
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        font-family: PingFangSC-Regular;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 33px;
        color: #555555;
      }
      :deep(.ivu-dropdown) {
        .ivu-dropdown-item {
          // font-size: 14px!important;
          color: #555555;
        }
      }
    }
  }
}
.term {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  .term-item {
    border: 1px solid #3d55a8;
    padding: 2px 8px;
    font-size: 14px;
    margin-right: 2px;
    display: flex;
    align-items: center;
    border-radius: 6px;
    color: #3d55a8;
    background-color: #eff3fe;
    cursor: pointer;
    margin-bottom: 5px;
    .icon {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: #f30707;
      margin-left: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        display: inline-block;
        width: 8px;
        height: 2px;
      }
    }
  }
  .term-item:hover {
    color: #ffffff;
    background-color: #3d55a8;
  }
}
.empty {
  height: calc(100% - 60px);
}
</style>