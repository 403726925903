<!-- 完成或未完成列表表头 -->
<template>
  <div>
    <div class="header">
      <h2>数据目录</h2>
      <div class="search-input">
        <Input placeholder="检索" @on-enter="handleSearch" v-model="keyword" />
        <div class="search-btn" @click="handleSearch">
          <img src="../../../assets/img/检索-选中.png" alt="" />
        </div>
      </div>
      <div class="button">
        <div class="btn btn-simple" @click="handleExport">导出列表</div>
        <CommonHeader />
      </div>
    </div>
    <Modal v-model="modal" sticky scrollable class="custom-modal-footer-block" @on-cancel="modal = false">
      <template #header>
        <p class="title">提醒</p>
        <div class="modal-close" @click="modal = false">
          关闭 <span>ESC</span>
        </div>
      </template>
      <div class="form">
        <Form label-position="top">
          <FormItem label="数据级别">
            <Select v-model="level1">
              <Option value="重要数据">重要数据</Option>
              <Option value="一般数据">一般数据</Option>
            </Select>
          </FormItem>
        </Form>
      </div>

      <template #footer>
        <div class="btn btn-default" @click="handleExport">确认</div>
      </template>
    </Modal>
  </div>
</template>

<script setup>
import {
  ref,
  defineEmits,
  inject,
  onMounted,
  watch,
  onBeforeUnmount,
} from "vue";
import { useRouter } from "vue-router";
import { Message } from "view-ui-plus";
import qs from "qs";

import bus from "@/utils/bus.js";
import CommonHeader from "@/components/commonHeader.vue";
import DownFiles from "@/utils/dowFile";
let isShow = ref(0); //0代表未完成，1代表已完成

let emit = defineEmits(["showNav"]);
function handleNav(val) {
  isShow.value = val;
  emit("showNav", isShow.value);
}
let keyword = ref("");

let axios = inject("axios");
let router = useRouter();

//导出
let modal = ref(false);
const confirmExport = () => {
  modal.value = true;
};
const handleExport = () => {
  console.log(total.value)
  if (total.value == 0) {
    return Message.error({
      background: true,
      content: "暂无可导出的数据",
      duration: 3,
    });
  } else {
    let params = {
      auditAgency: auditAgency.value.join(","),
      dataName: keyword.value,
      level: level.value,
      org: Organization.value.join(","),
      years: year.value.join(","),
      firsts: "",
      seconds: "",
      thirds: "",
      sort: sort.value,
    };
    axios.get('/data/exportJudge', { params }).then((res) => {
      if (res.data.code == 200) {
        axios.get('/data/export', { params, responseType: "blob" }).then((res) => {
          if (res.data.code == 500) {
            return Message.error({
              background: true,
              content: res.data.message,
              duration: 3
            })
          }
          // console.log(res);
          if (res.status == 200) {
            const blob = res.data;
            // 获取文件名
            const content = res.headers["content-disposition"];
            const fileName = content && content.split(";")[1].split("filename=")[1];
            DownFiles(blob, fileName);
            modal.value = false;
            level1.value = "";
          }
        });
      } else {
        Message.error({
          background: true,
          content: res.data.message,
          duration: 3
        })
      }
    });
  }
};

watch(keyword, (val) => {
  bus.emit("new-keyword", val);
});

let page = ref(1);

let Organization = ref([]); //所属机构
let auditAgency = ref([]); //审核机构
let level = ref("");
let level1 = ref("");
let type = ref([]);
let year = ref([]);
let sort = ref(""); //排序条件
const handleSearch = () => {
  bus.emit("on-search");
};

const analyse = (data) => {
  let { name, value } = data;
  switch (name) {
    case "organization":
      Organization.value = value
        .filter((item) => {
          return item.isChecked;
        })
        .map((item) => {
          return item._id;
        });
      break;
    case "evaluationOrg":
      auditAgency.value = value
        .filter((item) => {
          return item.isChecked;
        })
        .map((item) => {
          return item._id;
        });
      break;
    case "levelData":
      level.value = value
        .filter((item) => {
          return item.isChecked;
        })
        .map((item) => {
          return item._id;
        })
        .join(",");
      break;
    case "typeData":
      type.value = value.filter((item) => {
        return item.isChecked;
      });
      // .map((item) => {
      //   return item._id;
      // });
      break;
    case "yearsData":
      year.value = value
        .filter((item) => {
          return item.isChecked;
        })
        .map((item) => {
          return item._id;
        });
      break;
  }
};

let total = ref(0);
onMounted(() => {
  //接收左侧删除条件，并开始搜索
  bus.on("sendData1", (data) => {
    analyse(data);
  });
  bus.on("sort", (name) => {
    sort.value = name;
  });
  bus.on("page", (val) => {
    page.value = val;
  });
  bus.on("noticeTotal", (val) => {
    total.value = val;
  });
  handleSearch();
});
onBeforeUnmount(() => {
  bus.all.delete("sendData1");
  bus.all.delete("sort");
  bus.all.delete("page");
  bus.all.delete("noticeTotal");
});
</script>


<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding: 15px 0;
  height: 70px;
  > h2 {
    font-family: PingFangSC-Medium;
    font-size: 26px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 33px;
    letter-spacing: 0px;
    color: #111111;
  }
}
.button {
  display: flex;
  align-items: center;
  > div {
    margin-left: 20px;
  }
  .btn-add {
    color: #fff;
    cursor: pointer;
    i {
      padding-right: 5px;
    }
  }
  .icon {
    img {
      display: block;
      width: 20px;
      height: 18px;
    }
  }
}

.search-input {
  background: #f6f6f6;
  border-radius: 6px;
  width: 600px;
  height: 40px;
  display: flex;
  background-image: linear-gradient(#f6f6f6, #f6f6f6),
    linear-gradient(#2c399d, #2c399d);
  :deep(.ivu-input) {
    height: 40px;
    line-height: 40px;
    border: none;
    background: #f6f6f6;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    font-size: 16px;
    color: #111111;
    &::placeholder {
      font-family: PingFangSC-Regular;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 46px;
      color: #555555;
    }
  }
  .search-btn {
    width: 30px;
    height: 30px;
    margin: 5px;
    background: #3d55a8;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    img {
      display: block;
      width: 16px;
      height: 16px;
    }
  }
}
</style>