<template>
  <div class="aside-search">
    <div class="collapse-tab">
      <div class="title" @click="handleCollapse('organization')">
        <div class="left">
          <span>所属机构</span>
        </div>
        <div class="right">
          <Icon
            :type="
              leftObj.organization.open ? 'ios-arrow-up' : 'ios-arrow-down'
            "
          />
        </div>
      </div>
      <div class="list" v-if="leftObj.organization.open">
        <div class="input-search" v-if="true">
          <input type="text" v-model="searchOrgVal" placeholder="关键字" />
          <Icon type="ios-search" />
        </div>
        <template v-if="leftObj.organization.data.length > 0">
          <Checkbox
            v-for="(item, i) in leftObj.organization.data"
            :label="item.label"
            :key="i"
            v-model="item.isChecked"
          >
            <div class="flex-center-between">
              <span :title="item._id">{{ item._id }}</span>
              <span> ({{ item.count }})</span>
            </div>
          </Checkbox>
        </template>
        <div class="not-data-watp" v-else>
          <img src="../assets/img/UniversalBlank.png" />
        </div>
      </div>
    </div>
    <div class="collapse-tab">
      <div class="title" @click="handleCollapse('evaluationOrg')">
        <div class="left">
          <span>复核机构</span>
        </div>
        <div class="right">
          <Icon
            :type="
              leftObj.evaluationOrg.open ? 'ios-arrow-up' : 'ios-arrow-down'
            "
          />
        </div>
      </div>
      <div class="list" v-if="leftObj.evaluationOrg.open">
        <div class="input-search" v-if="true">
          <input type="text" v-model="searchEvaVal" placeholder="关键字" />
          <Icon type="ios-search" />
        </div>
        <template v-if="leftObj.evaluationOrg.data.length > 0">
          <Checkbox
            v-for="(item, i) in leftObj.evaluationOrg.data"
            :label="item.label"
            :key="i"
            v-model="item.isChecked"
          >
            <div class="flex-center-between">
              <span :title="item._id">{{ item._id }}</span>
              <span> ({{ item.count }})</span>
            </div>
          </Checkbox>
        </template>
        <div class="not-data-watp" v-else>
          <img src="../assets/img/UniversalBlank.png" />
        </div>
      </div>
    </div>
    <div class="collapse-tab">
      <div class="title" @click="handleCollapse('levelData')">
        <div class="left">
          <span>等级</span>
        </div>
        <div class="right">
          <Icon
            :type="leftObj.levelData.open ? 'ios-arrow-up' : 'ios-arrow-down'"
          />
        </div>
      </div>
      <div class="list" v-if="leftObj.levelData.open">
        <!-- <div class="input-search" v-if="false">
          <input type="text" v-model="searchVal" placeholder="关键字" />
          <Icon type="ios-search" />
        </div> -->
        <template v-if="leftObj.levelData.data.length > 0">
          <Checkbox
            v-for="(item, i) in leftObj.levelData.data"
            :label="item.label"
            :key="i"
            v-model="item.isChecked"
          >
            <div class="flex-center-between">
              <span :title="item._id">{{ item._id }}</span>
              <span> ({{ item.count }})</span>
            </div>
          </Checkbox>
        </template>
        <div class="not-data-watp" v-else>
          <img src="../assets/img/UniversalBlank.png" />
        </div>
      </div>
    </div>
    <div class="collapse-tab">
      <div class="title" @click="handleCollapse('typeData')">
        <div class="left">
          <span>分类</span>
        </div>
        <div class="right">
          <Icon
            :type="leftObj.typeData.open ? 'ios-arrow-up' : 'ios-arrow-down'"
          />
        </div>
      </div>
      <div class="list" v-if="leftObj.typeData.open">
        <!-- <div class="input-search" v-if="true">
          <input type="text" v-model="searchVal" placeholder="关键字" />
          <Icon type="ios-search" />
        </div> -->
        <!-- <template v-if="leftObj.typeData.data.length > 0">
          <el-tree
            ref="treeRef"
            class="filter-tree"
            :props="defaultProps"
            lazy
            node-key="_id"
            :data="leftObj.typeData.data"
            show-checkbox
            :load="loadData"
            @check="getCheckNode"
            v-if="leftObj.typeData.data.length > 0"
          >
            <template #default="{ node, data }">
              <span class="custom-tree-node">
                <span>{{ data._id }}</span>
                <span>({{ data.count }})</span>
              </span>
            </template>
          </el-tree>
        </template> -->
        <template v-if="leftObj.typeData.data.length > 0">
          <Checkbox
            v-for="(item, i) in leftObj.typeData.data"
            :label="item.label"
            :key="i"
            v-model="item.isChecked"
          >
            <div class="flex-center-between">
              <span :title="item._id">{{ item._id }}</span>
              <span> ({{ item.count }})</span>
            </div>
          </Checkbox>
        </template>
        <div class="not-data-watp" v-else>
          <img src="../assets/img/UniversalBlank.png" />
        </div>
      </div>
    </div>
    <div class="collapse-tab">
      <div class="title" @click="handleCollapse('yearsData')">
        <div class="left">
          <span>年份</span>
        </div>
        <div class="right">
          <Icon
            :type="leftObj.yearsData.open ? 'ios-arrow-up' : 'ios-arrow-down'"
          />
        </div>
      </div>
      <div class="list" v-if="leftObj.yearsData.open">
        <!-- <div class="input-search" v-if="false">
          <input type="text" v-model="searchVal" placeholder="关键字" />
          <Icon type="ios-search" />
        </div> -->
        <template v-if="leftObj.yearsData.data.length > 0">
          <Checkbox
            v-for="(item, i) in leftObj.yearsData.data"
            :label="item.label"
            :key="i"
            v-model="item.isChecked"
          >
            <div class="flex-center-between">
              <span :title="item._id">{{ item._id }}</span>
              <span> ({{ item.count }})</span>
            </div>
          </Checkbox>
        </template>
        <div class="not-data-watp" v-else>
          <img src="../assets/img/UniversalBlank.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import CollapseTab from "@/components/CollapseTab";
import tree from "@/components/tree";
import bus from "@/utils/bus";
import { onBeforeUnmount, ref, toRefs, onMounted, inject, watch } from "vue";
import moment from "moment";
import { useRoute } from "vue-router";

let route = useRoute();
const defaultProps = {
  label: "_id",
  isLeaf: "leaf",
  children: "children",
};
let axios = inject("axios");

let keyword = ref("");
//查询刷选条件
const getFilterResult = () => {
  let url = `/data/filter`;
  let params = {
    dataName: keyword.value,
  };
  axios.get(url, { params }).then((res) => {
    if (res.data.code == 200) {
      let data = res.data.data;
      leftObj.value = {
        organization: {
          open: true,
          data: data.submitInst.map((item) => {
            return {
              ...item,
              isChecked: false,
            };
          }),
        },
        yearsData: {
          open: false,
          data: data.year.map((item) => {
            return {
              ...item,
              isChecked: false,
            };
          }),
        },
        evaluationOrg: {
          open: false,
          data: data.auditAgency.map((item) => {
            return {
              ...item,
              isChecked: false,
              _id: item.agency,
            };
          }),
        },
        levelData: {
          open: false,
          data: data.level.map((item) => {
            return {
              ...item,
              isChecked: false,
            };
          }),
        },
        typeData: {
          open: false,
          data: data.first.map((item) => {
            return {
              ...item,
              // expand: true,
              // selected: false,
              checked: false,
              level: 1,
              leaf: true,
              isChecked: false,
            };
          }),
        },
      };
      organization.value = leftObj.value.organization.data;
      evaluationOrg.value = leftObj.value.evaluationOrg.data;
      let query = route.query;
      if (query.name) {
        console.log(leftObj.value.organization);
        leftObj.value.organization.data.forEach((item) => {
          if (item._id == query.name) {
            item.isChecked = true;
          }
        });
      }
    }
  });
};

let leftObj = ref({
  organization: {
    open: true,
    data: [],
  },
  yearsData: {
    open: false,
    data: [],
  },
  evaluationOrg: {
    open: false,
    data: [],
  },
  levelData: {
    open: false,
    data: [],
  },
  typeData: {
    open: false,
    data: [],
  },
});

let organization = ref([]);
let evaluationOrg = ref([]);
//切换
const handleCollapse = (name) => {
  leftObj.value[name].open = !leftObj.value[name].open;
};

watch(
  leftObj,
  (val) => {
    bus.emit("sendData", val);
  },
  { deep: true }
);

watch(
  () => leftObj.value.organization.data,
  (val) => {
    bus.emit("sendData1", { name: "organization", value: val });
  },
  { deep: true }
);
watch(
  () => leftObj.value.evaluationOrg.data,
  (val) => {
    bus.emit("sendData1", { name: "evaluationOrg", value: val });
  },
  { deep: true }
);
watch(
  () => leftObj.value.levelData.data,
  (val) => {
    bus.emit("sendData1", { name: "levelData", value: val });
  },
  { deep: true }
);
watch(
  () => leftObj.value.typeData.data,
  (val) => {
    bus.emit("sendData1", { name: "typeData", value: val });
  },
  { deep: true }
);
watch(
  () => leftObj.value.yearsData.data,
  (val) => {
    bus.emit("sendData1", { name: "yearsData", value: val });
  },
  { deep: true }
);

let treeRef = ref(null);
//加载数据
const loadData = async (node, resolve) => {
  if (node.level == 0) {
    resolve(leftObj.value.typeData.data);
  }
  if (node.level == 1) {
    let arr = await getSecondNodes(node, node.data._id);
    resolve(arr);
  }
  if (node.level == 2) {
    let arr = await getThirdNodes(node, node.data._id);
    resolve(arr);
  }
  if (node.level > 2) {
    return resolve([]);
  }
};

//获取当前选中的节点数据
const getCheckNode = (obj, selectObj) => {
  if (treeRef.value != null && treeRef.value != undefined) {
    let newArr = [...treeRef.value.getCheckedNodes(false, false)];
    let arr = [...newArr];

    let data = leftObj.value.typeData.data;
    data.forEach((item) => {
      item.isChecked = false;
    });
    if (arr.length > 0) {
      arr.forEach((each) => {
        data.forEach((item) => {
          if (item._id == each._id) {
            item.isChecked = true;
          }
        });
      });
    }
  }
};

const setCheckedNodes = (arr) => {
  if (treeRef.value != null && treeRef.value != undefined) {
    treeRef.value.setCheckedNodes(arr, false);
  }
};
//获取二级节点数据
const getSecondNodes = (node, name) => {
  return new Promise((resolve, reject) => {
    let url = `/data/second?first=${name}`;
    axios
      .get(url)
      .then((res) => {
        if (res.data.code == 200) {
          let data = res.data.data.map((item) => {
            return {
              ...item,
              expand: true,
              selected: false,
              checked: false,
              level: 2,
              leaf: false,
              _id: item._id.length == 1 ? item._id[0] : item._id,
            };
          });
          // console.log(node);
          node.data.children = data;
          // console.log(data);
          resolve(data);
        } else {
          resolve([]);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//获取三级节点数据
const getThirdNodes = (name) => {
  return new Promise((resolve, reject) => {
    let url = `/data/third?second=${name}`;
    axios
      .get(url)
      .then((res) => {
        if (res.data.code == 200) {
          let data = res.data.data.map((item) => {
            return {
              ...item,
              level: 3,
              leaf: true,
              _id: item._id.length == 2 ? item._id[1] : item._id,
            };
          });
          if (data.length > 0) {
            resolve(data);
          } else {
            resolve([]);
          }
        } else {
          resolve([]);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

let searchOrgVal = ref("");
let searchEvaVal = ref("");
watch(searchOrgVal, (val) => {
  let newArr = organization.value;
  if (val != "") {
    let arr = newArr.filter((item) => item._id.indexOf(val) > -1);
    leftObj.value.organization.data = arr;
  } else {
    leftObj.value.organization.data = newArr;
  }
});
watch(searchEvaVal, (val) => {
  let newArr = evaluationOrg.value;
  if (val != "") {
    let arr = newArr.filter((item) => item._id.indexOf(val) > -1);
    leftObj.value.evaluationOrg.data = arr;
  } else {
    leftObj.value.evaluationOrg.data = newArr;
  }
});

onMounted(() => {
  getFilterResult();
  //接收头部组件传递过来的新的关键词
  bus.on("new-keyword", (val) => {
    keyword.value = val;
    // getFilterResult();
  });
  //头部组件点击搜索
  bus.on("on-search", () => {
    getFilterResult();
  });
  //接收列表上方传回来的数据
  bus.on("returnData", (obj) => {
    let arr = obj.typeData.data.filter((item) => item.isChecked == true);
    setCheckedNodes(arr);
  });
});
onBeforeUnmount(() => {
  bus.all.delete("new-keyword");
  bus.all.delete("on-search");
  bus.all.delete("returnData");
});
</script>

<style lang="scss" scoped>
.aside-search {
  img {
    width: 15px;
    margin-right: 5px;
    display: block;
  }
  .item {
    margin-bottom: 10px;
  }
  .filter-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #888888;
    :deep(.ivu-input) {
      border-radius: 6px;
    }
    span {
      color: #888888;
      padding: 0 2px;
    }
  }
  .btn-black {
    width: 40px;
    height: 24px;
    line-height: 24px;
    font-size: 12px;
    color: #555555;
    text-align: center;
    border: solid 1px #555555;
  }
  .btn-black:hover {
    color: #fff;
    cursor: pointer;
  }
}
.collapse-tab {
  // max-width: 360px;
  background: #ffffff;
  border-radius: 8px;
  user-select: none;
  .title {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    padding: 10px 0;
    font-size: 16px;
    color: #555555;
    font-weight: bold;
    .left {
      display: flex;
      align-items: center;
    }
  }
  .list {
    // margin: 0 10px;
    // padding-bottom: 10px;
    border: solid 1px #eeeeee;
    border-radius: 10px;
    .input-search {
      border-bottom: 1px solid #eee;
      // background-color: #fff;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      overflow: hidden;
      i {
        padding: 0 10px;
        font-size: 18px;
        float: right;
        line-height: 40px;
        color: #111111;
      }
      input {
        border: 0;
        outline: none;
        height: 32px;
        padding-left: 10px;
        line-height: 40px;
        height: 40px;
        color: #555555;
        &::placeholder {
          color: #888888;
        }
      }
    }
    ::v-deep .ivu-checkbox-checked .ivu-checkbox-inner {
      border-color: #3d55a8;
      background-color: #3d55a8;
    }
    :deep(.ivu-checkbox-wrapper) {
      display: flex;
      align-items: center;
      padding: 8px 10px;
      margin-right: 0;
      // margin-top: 10px;
      > span:first-child {
        margin-right: 10px;
      }
      .ivu-checkbox-label-text {
        width: calc(100% - 30px);
        margin-right: 0;
        > div {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          span {
            &:nth-child(1) {
              width: 75%;
              margin-right: 5px;
              // width: 70%;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              word-break: break-all;
              color: #555555;
              font-size: 14px;
              font-weight: normal;
            }
            &:nth-child(2) {
              text-align: right;
              color: #888888;
              font-size: 14px;
              font-weight: normal;
            }
          }
        }
      }
    }
    :deep(.ivu-checkbox-wrapper-checked) {
      .ivu-checkbox-label-text {
        width: calc(100% - 30px);
        > div {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          > span {
            color: #3d55a8 !important;
          }
        }
      }
    }

    ::v-deep .ivu-checkbox-wrapper-checked {
      color: #3d55a8;
    }
    ::v-deep .ivu-checkbox-group {
      background-color: #fff;
      max-height: 200px;
      overflow-y: auto;
      overflow-x: hidden;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      label {
        width: 100%;
        padding: 10px 10px;
        span:last-child {
          float: right;
        }
      }
    }
    .not-data-watp {
      text-align: center;
      background-color: #fff;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      padding: 30px 0;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      img {
        width: 30%;
      }
    }
    :deep(.el-tree-node__content) {
      margin: 10px;
      .el-tree-node__label {
        width: calc(100% - 38px);
        .custom-tree-node {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
    :deep(.el-checkbox__input.is-checked) {
      .el-checkbox__inner {
        border-color: #3d55a8;
        background-color: #3d55a8;
      }
    }
    :deep(.el-tree-node) {
      .el-tree-node__label {
        .custom-tree-node {
          span {
            &:nth-child(1) {
              width: 75%;
              margin-right: 5px;
              // width: 70%;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              word-break: break-all;
              color: #555555;
              font-size: 14px;
              font-weight: normal;
            }
            &:nth-child(2) {
              text-align: right;
              color: #888888;
              font-size: 14px;
              font-weight: normal;
            }
          }
        }
      }
    }
    :deep(.is-checked) {
      .el-tree-node__label {
        .custom-tree-node {
          span {
            color: #3d55a8 !important;
          }
        }
      }
    }
    :deep(.el-tree-node__expand-icon.is-leaf) {
      display: none;
    }
  }
}
</style>